/** Core dependencies */
import React, {
    useEffect,
    useState,
} from 'react';
import { useLocation } from "react-router-dom";


/** Third party dependencies & libraries */
import { Form, Input, Button, Checkbox, message, Row, Col, Typography, notification } from 'antd';
import { LoginAsync, loginData } from '../../features/auth/auth';
import { useDispatch } from 'react-redux';


/** Local components */
import Sider from '../../component/sider/sider'


/** Local statics & imports */
import './login.css'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { bearerToken } from '../../apiinteraction/apiinteraction';


function Login() {

    const { Title, Text } = Typography;

    const search = useLocation().search;

    const history = useHistory();

    const dispatch = useDispatch();

    const validateMessage = (data) => {
        const args = {
            message: 'Error',
            description:
                `${data?.message}`,
            duration: 5,
        };
        notification.error(args);
    };

    const onFinish = async (values) => {

        try {
            let result = await dispatch(LoginAsync(values));

            if (result.payload.success == true) {
                history.push('./dashboard')
            }
            else {
                console.log(result.payload)
                validateMessage(result.payload.data);
            }
        }
        catch (err) {
            console.log(err)
        }
    };

    const onFinishFailed = (errorInfo) => {
        message.info('Failed:', errorInfo);
    };

    const checkForSystemToken = async () => {
        try {
            const token = new URLSearchParams(search).get('token');

            if (!token)
                return;

            let result = await bearerToken({ token });

            if (result.success == true) {
                localStorage.setItem('token', result.message.token)
                history.push('./dashboard')
            }
            else {
                validateMessage(result.payload.data);
            }

        } catch (err) {
            console.log(err)
        }

    }

    useEffect(checkForSystemToken, [])

    return (
        <div>

            <Row style={{ justifyContent: 'center' }}>
                <Col className="login-cover-image hide-on-mobile" span={10}>
                    <Sider />
                </Col>

                <Col span={14} style={{ width: '100%' }} >
                    <div style={{ marginTop: '20%' }}>
                        <Row style={{ justifyContent: 'center' }}>
                            <Title className="blue-color">Login</Title>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                            <Form
                                name="basic"
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
                                    label="Email"
                                    name="emailAddress"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input className='height-50' />
                                </Form.Item>

                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password className='height-50' />
                                </Form.Item>

                                {/* <Form.Item name="remember" valuePropName="checked" wrapperCol={{ span: 16 }}>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item> */}

                                <Form.Item wrapperCol={{ span: 24 }}>
                                    {/* <Link to='./dashboard'> */}
                                    <Button className='w-100 blue-background login-button' type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                    {/* </Link> */}
                                </Form.Item>
                            </Form >
                        </Row >
                    </div >
                </Col >
            </Row >
        </div >
    )
}

export default Login;
