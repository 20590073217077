// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-dropdown {
    height: 50px;
    border-radius: 20px;
}

.header-dropdown button {
    /* background: none; */
    border: none;
    background: white;
    height: 40px;
    border-radius: 20px;
}

.bell-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none
}

.header-button {
    height: 50px;
    background: none;
    border: none
}

.sub-heading {
    font-size: 16px !important;
}

.Admin-bell {
    height: 3rem;
    border-radius: 1px;
    width: 9rem;
}

.image-col {
    padding-right: 1rem;
    padding-top: 4px;
}

.admin-text {
    padding-right: 5px;
    padding-top: 10px;
}

.admin-icon {
    padding-right: 9px;
    padding-top: 16px;
}

.admin-button {
    display: flex;
    height: 50px;
    width: 9rem;
    background-color: white;
    border: none;
    border-radius: 10px;
}

.ant-page-header-heading-extra {
    display: flex;
}

.search-bar-custom {
    width: 250px !important;
    height: 50px;
    background: #F8F8F8;
    border: none;
}

.search-bar-custom input {
    background: #F8F8F8;
}
`, "",{"version":3,"sources":["webpack://./src/component/header/header.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB;AACJ;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB;AACJ;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".header-dropdown {\r\n    height: 50px;\r\n    border-radius: 20px;\r\n}\r\n\r\n.header-dropdown button {\r\n    /* background: none; */\r\n    border: none;\r\n    background: white;\r\n    height: 40px;\r\n    border-radius: 20px;\r\n}\r\n\r\n.bell-icon {\r\n    width: 50px;\r\n    height: 50px;\r\n    border-radius: 50%;\r\n    border: none\r\n}\r\n\r\n.header-button {\r\n    height: 50px;\r\n    background: none;\r\n    border: none\r\n}\r\n\r\n.sub-heading {\r\n    font-size: 16px !important;\r\n}\r\n\r\n.Admin-bell {\r\n    height: 3rem;\r\n    border-radius: 1px;\r\n    width: 9rem;\r\n}\r\n\r\n.image-col {\r\n    padding-right: 1rem;\r\n    padding-top: 4px;\r\n}\r\n\r\n.admin-text {\r\n    padding-right: 5px;\r\n    padding-top: 10px;\r\n}\r\n\r\n.admin-icon {\r\n    padding-right: 9px;\r\n    padding-top: 16px;\r\n}\r\n\r\n.admin-button {\r\n    display: flex;\r\n    height: 50px;\r\n    width: 9rem;\r\n    background-color: white;\r\n    border: none;\r\n    border-radius: 10px;\r\n}\r\n\r\n.ant-page-header-heading-extra {\r\n    display: flex;\r\n}\r\n\r\n.search-bar-custom {\r\n    width: 250px !important;\r\n    height: 50px;\r\n    background: #F8F8F8;\r\n    border: none;\r\n}\r\n\r\n.search-bar-custom input {\r\n    background: #F8F8F8;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
