// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/sider.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-cover-image{
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    height: 100vh;
    width: 100%;
    position: relative;
}
.logo-row{
    margin-left:50px;
    margin-top:50px;
}
.login-page-content{
    padding: 20px;
    position: absolute;
    top: 30%;
    left:50px;
}
.login-page-para{
    position: absolute;
    top: 55%;
    left:50px;
}
.login-heading{
    font-size: 50px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: lighter !important;
}
.login-para{
    font-size: 20px;
    width: 90%;
    font-family: 'Poppins', sans-serif;
    font-weight: 400 !important;
}
.border-radius-10{
    border-radius: 10px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1CA0F0;
    border-color: #1CA0F0;
}
`, "",{"version":3,"sources":["webpack://./src/component/sider/sider.css"],"names":[],"mappings":"AAAA;IACI,yDAAsD;IACtD,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;AACb;AACA;IACI,0BAA0B;IAC1B,kCAAkC;IAClC,+BAA+B;AACnC;AACA;IACI,eAAe;IACf,UAAU;IACV,kCAAkC;IAClC,2BAA2B;AAC/B;AACA;IACI,mBAAmB;AACvB;AACA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".login-cover-image{\r\n    background-image: url('../../assets/images/sider.png');\r\n    background-size: cover;\r\n    height: 100vh;\r\n    width: 100%;\r\n    position: relative;\r\n}\r\n.logo-row{\r\n    margin-left:50px;\r\n    margin-top:50px;\r\n}\r\n.login-page-content{\r\n    padding: 20px;\r\n    position: absolute;\r\n    top: 30%;\r\n    left:50px;\r\n}\r\n.login-page-para{\r\n    position: absolute;\r\n    top: 55%;\r\n    left:50px;\r\n}\r\n.login-heading{\r\n    font-size: 50px !important;\r\n    font-family: 'Poppins', sans-serif;\r\n    font-weight: lighter !important;\r\n}\r\n.login-para{\r\n    font-size: 20px;\r\n    width: 90%;\r\n    font-family: 'Poppins', sans-serif;\r\n    font-weight: 400 !important;\r\n}\r\n.border-radius-10{\r\n    border-radius: 10px;\r\n}\r\n.ant-checkbox-checked .ant-checkbox-inner {\r\n    background-color: #1CA0F0;\r\n    border-color: #1CA0F0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
