// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carrousel-container {
    height: 100% !important;
    width: 100% !important;
}

.carrousel-container-image {
    height: 100% !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 770px) {
    .carrousel-container-image {
        height: 300px !important;
    }
}
`, "",{"version":3,"sources":["webpack://./src/component/Carrousel/Slideshow.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".carrousel-container {\r\n    height: 100% !important;\r\n    width: 100% !important;\r\n}\r\n\r\n.carrousel-container-image {\r\n    height: 100% !important;\r\n    width: 100% !important;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n@media screen and (max-width: 770px) {\r\n    .carrousel-container-image {\r\n        height: 300px !important;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
