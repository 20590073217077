// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-form{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: fit-content;
}

.filter-header{
    display: flex;
    justify-content: space-between;
}

.filter-modal{
    max-width: 400px;
    height: fit-content;
}

.filter-input{
    border: none;
    border-bottom: 1px solid black;
    outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/component/modals/modals.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,8BAA8B;IAC9B,aAAa;AACjB","sourcesContent":[".filter-form{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-evenly;\r\n    height: fit-content;\r\n}\r\n\r\n.filter-header{\r\n    display: flex;\r\n    justify-content: space-between;\r\n}\r\n\r\n.filter-modal{\r\n    max-width: 400px;\r\n    height: fit-content;\r\n}\r\n\r\n.filter-input{\r\n    border: none;\r\n    border-bottom: 1px solid black;\r\n    outline: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
