// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-button {
    background: #1CA0F0 !important;
    color: white;
    border: 1px solid #1CA0F0;
}

.custom-button:hover {
    background: #1CA0F0 !important;
    color: white;
}


.dashboard_filter-button {
    width: 100%;
    border-radius: 10px;
    height: 40px;
}

.filter-span{
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: red;
    margin-left: 2px;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/dashboard.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,YAAY;IACZ,yBAAyB;AAC7B;;AAEA;IACI,8BAA8B;IAC9B,YAAY;AAChB;;;AAGA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,WAAW;IACX,qBAAqB;IACrB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".custom-button {\r\n    background: #1CA0F0 !important;\r\n    color: white;\r\n    border: 1px solid #1CA0F0;\r\n}\r\n\r\n.custom-button:hover {\r\n    background: #1CA0F0 !important;\r\n    color: white;\r\n}\r\n\r\n\r\n.dashboard_filter-button {\r\n    width: 100%;\r\n    border-radius: 10px;\r\n    height: 40px;\r\n}\r\n\r\n.filter-span{\r\n    border-radius: 50%;\r\n    height: 10px;\r\n    width: 10px;\r\n    background-color: red;\r\n    margin-left: 2px;\r\n    margin-bottom: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
