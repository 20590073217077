// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover-image {
    width: 100% !important;
    height: 100% !important;
    
}

.ant-card-body {
    padding: 5px !important;
}

.alertCard {
    height: 100% !important;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/component/alertCard/alertCard.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;;AAE3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,aAAa;AACjB","sourcesContent":[".cover-image {\r\n    width: 100% !important;\r\n    height: 100% !important;\r\n    \r\n}\r\n\r\n.ant-card-body {\r\n    padding: 5px !important;\r\n}\r\n\r\n.alertCard {\r\n    height: 100% !important;\r\n    display: flex;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
