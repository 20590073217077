// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    /* background: rgba(255, 255, 255, 0.2); */
  }
  
  .ant-layout-sider-children {
    height: 100vh !important;
  }
  
  .site-layout-sub-header-background {
    background: #fff;
  }
  
  .site-layout-background {
    background: #fff;
  }
  .ant-layout-sider{
    background: none;
  }
  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{
    border-right: none !important;
  }
  .ant-menu{
    font-size: 16px !important;
  }
  .ant-menu-item{
    margin-bottom:20px !important;
    margin-top: 20px !important;
    
  }
  .ant-menu > .ant-menu-item:hover,
  .ant-menu > .ant-menu-submenu:hover,
  .ant-menu > .ant-menu-item-active,
  .ant-menu> .ant-menu-submenu-active,
  .ant-menu > .ant-menu-item-open,
  .ant-menu> .ant-menu-submenu-open,
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-submenu-selected {
    /* color: #26B523 !important;
    background: #EFFFEF !important; */
    border-radius: 20px;
  }
  .ant-menu{
    color: #D4D4D4;
  }
  .custom-sidebar{
    
  }

  
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/component/siderbar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,0CAA0C;EAC5C;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;EACA;IACE,gBAAgB;EAClB;EACA;IACE,6BAA6B;EAC/B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,6BAA6B;IAC7B,2BAA2B;;EAE7B;EACA;;;;;;;;IAQE;qCACiC;IACjC,mBAAmB;EACrB;EACA;IACE,cAAc;EAChB;EACA;;EAEA","sourcesContent":["#components-layout-demo-responsive .logo {\r\n    height: 32px;\r\n    margin: 16px;\r\n    /* background: rgba(255, 255, 255, 0.2); */\r\n  }\r\n  \r\n  .ant-layout-sider-children {\r\n    height: 100vh !important;\r\n  }\r\n  \r\n  .site-layout-sub-header-background {\r\n    background: #fff;\r\n  }\r\n  \r\n  .site-layout-background {\r\n    background: #fff;\r\n  }\r\n  .ant-layout-sider{\r\n    background: none;\r\n  }\r\n  .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after{\r\n    border-right: none !important;\r\n  }\r\n  .ant-menu{\r\n    font-size: 16px !important;\r\n  }\r\n  .ant-menu-item{\r\n    margin-bottom:20px !important;\r\n    margin-top: 20px !important;\r\n    \r\n  }\r\n  .ant-menu > .ant-menu-item:hover,\r\n  .ant-menu > .ant-menu-submenu:hover,\r\n  .ant-menu > .ant-menu-item-active,\r\n  .ant-menu> .ant-menu-submenu-active,\r\n  .ant-menu > .ant-menu-item-open,\r\n  .ant-menu> .ant-menu-submenu-open,\r\n  .ant-menu > .ant-menu-item-selected,\r\n  .ant-menu > .ant-menu-submenu-selected {\r\n    /* color: #26B523 !important;\r\n    background: #EFFFEF !important; */\r\n    border-radius: 20px;\r\n  }\r\n  .ant-menu{\r\n    color: #D4D4D4;\r\n  }\r\n  .custom-sidebar{\r\n    \r\n  }\r\n\r\n  \r\n  \r\n  \r\n  \r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
